<template>
    <div id="redirect-from-google-calendar">


      <AuthPageCard>

        <div class="text-center">
          <h2>
            {{$t('google_calendar_redirect.title')}}
          </h2>
          <p>
            {{$t('google_calendar_redirect.text')}}
          </p>

          <p class="mt-4">
            {{$t('google_calendar_redirect.dietitian')}}

          </p>

          <b-button variant="primary" @click="$router.push({name: 'login'})">
            {{$t('google_calendar_redirect.button')}}
          </b-button>

        </div>
      </AuthPageCard>


    </div>

</template>
<style lang="scss">
</style>

<script>

import AuthPageCard from "@components/auth/AuthPageCard";
export default{
  components: {AuthPageCard},
  props:{

  },
  data(){
    return{
    }
  },

  methods:{

  }
}
</script>